import React from "react"
import { Link, graphql } from "gatsby"

import Header from "../components/Layout/header"
import Footer from "../components/Layout/footer"
import SEO from "../components/Global/MetaHead"

const ThankYouPage = ({ data }) => (
  <>
    <SEO
      title="Дякуємо - Центр Державних Ліцензій України"
      description="Ваше звернення успішно відправлено! Зазвичай наші фахівці зв'язуються протягом 15 хвилин."
      image={`https:${data.ogImage.fixed.src}`}
      url="/dyakuemo/"
      lang="uk"
      noindex="noindex"
    />
    <Header />
    <section className="dyakuemo container py-5">
      <h1>Дякуємо!</h1>
      <p className="text-center mt-2">
        <span role="img" aria-label="checkbox">
          ✔️
        </span>
        Ваше звернення успішно відправлено!
        <br />
      </p>

      <div className="pt-3 pb-2 title2">Що можете зробити далі?</div>
      <ol>
        <li className="mb-4">
          Отримати термінову безкоштовну консультацію за телефонами:
          <br />
          Чіп Карти +38(067) 819-02-72;
          <br />
          Ліцензування або інші послуги +38(068) 332-88-08, +38(099) 444-01-24.
        </li>
        <li>
          Перейти на{" "}
          <Link to="/" replace>
            головну сторінку
          </Link>{" "}
          і продовжити роботу із сайтом.
        </li>
      </ol>
      {/* <div className="container text-center">
        <div className="pt-3 pb-2 title2">Долучайтеся у Фейсбуці</div>
      </div> */}
    </section>
    <Footer />
  </>
)
export const query = graphql`
  {
    ogImage: contentfulAsset(
      file: { fileName: { eq: "center-licenziy-opengraph-min.png" } }
    ) {
      title
      id
      fixed(width: 620, height: 460) {
        src
      }
    }
  }
`

export default ThankYouPage
